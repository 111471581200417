import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-8 bg-white flex flex-col  text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex z-20" }
const _hoisted_3 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_4 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateNotification = _resolveComponent("UpdateNotification")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Search = _resolveComponent("Search")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Print = _resolveComponent("Print")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_UpdateNotification, {
        successSttPieces: 30,
        errorSttPieces: 10,
        modelValue: _ctx.updateNotificationModel,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateNotificationModel = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_Breadcrumb),
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getTitle), 1),
            _createVNode(_component_Search, {
              class: "w-64 self-center",
              onInput: _ctx.onSearch,
              onClear: _ctx.clearSearch,
              value: _ctx.searchValue,
              placeholder: "Cari ID Manifest",
              minChar: 1,
              timeout: 1000
            }, null, 8, ["onInput", "onClear", "value"]),
            _createVNode(_component_time_range_picker, {
              default: "7 hari terakhir",
              startDate: _ctx.startDate,
              endDate: _ctx.endDate,
              onSelect: _ctx.setDateRange
            }, null, 8, ["startDate", "endDate", "onSelect"])
          ]),
          (_ctx.ableToCreate)
            ? (_openBlock(), _createBlock(_component_lp_button, {
                key: 0,
                onClick: _ctx.goToUpdate,
                title: "Update STI-DEST",
                textColor: "white"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.stiDestList.length === 0 && !_ctx.searchValue && !_ctx.startDate && !_ctx.endDate)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            data: "station transit in destination"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            columns: _ctx.columns,
            data: _ctx.stiDestList,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.pagination = $event)),
            onRequest: _ctx.fetchStiDestList,
            errorCause: _ctx.errorCause,
            customButtonAction: _ctx.customButton,
            onTryAgain: _ctx.fetchStiDestList,
            isDetailAble: _ctx.ableToDetail,
            onClick: _ctx.goToDetail
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "customButtonAction", "onTryAgain", "isDetailAble", "onClick"]))
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_Print, { ref: "print" }, null, 512)
    ])
  ], 64))
}