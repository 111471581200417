
import { Vue, Options } from "vue-class-component";
import UpdateNotification from "@/app/ui/views/incoming-process/sti-dest/component/update-notification.vue";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import { StiDestController } from "@/app/ui/controllers/StiDestController";
import Print from "../modules/print.vue";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { StiDestData, StiDestDetail, StiDestManifestData } from "@/domain/entities/StiDest";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { FlagsPermissionIncomingProcessSTIDest } from "@/feature-flags/flags-incoming-process-sti-dest";
import { formatDateNormal } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    UpdateNotification,
    Print,
    EmptyState,
  },
})
export default class STIDestListReverseScan extends Vue {
  refs: any = "";
  print: any = "";
  beforeMount() {
    StiDestController.setFilter({
      startDate: "",
      endDate: "",
      search: "",
    });
  }
  mounted() {
    this.refs = this.$refs;
    StiDestController.setFirstPage();
  }

  updateNotificationModel = false;

  fetchStiDestList() {
    StiDestController.fetchStiDestList("v2");
  }

  get getTitle() {
    return this.$route.meta.title;
  }

  get ableToCreate() {
    return FlagsPermissionIncomingProcessSTIDest.permission_sti_dest_create.isEnabled();
  }

  get ableToDetail() {
    return FlagsPermissionIncomingProcessSTIDest.permission_sti_dest_detail.isEnabled();
  }

  // loading
  get isLoading() {
    return StiDestController.isLoading;
  }

  // error cause
  get errorCause() {
    return StiDestController.errorCause;
  }

  // filter date
  get startDate() {
    return StiDestController.filter.startDate;
  }

  get endDate() {
    return StiDestController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    StiDestController.setStartDate(value[0]);
    StiDestController.setEndDate(value[1]);
    StiDestController.setFirstPage();
    value[0] && value[1] && this.fetchStiDestList();
  }

  // filter search
  get searchValue() {
    return StiDestController.filter.search;
  }

  onSearch(value: string) {
    StiDestController.setSearch(value);
    StiDestController.setFirstPage();
    this.fetchStiDestList();
  }

  clearSearch() {
    StiDestController.setSearch("");
    this.fetchStiDestList();
  }

  // go to update sti-dest
  goToUpdate() {
    this.$router.push("/sti-dest/update");
  }

  goToDetail(item: StiDestData) {
    this.$router.push(`/sti-dest/detail/${item.stiDestId}`);
  }

  // Table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (item: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        },
      },
      {
        name: "ID Manifest",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestId}</div>`;
        },
      },
      {
        name: "Total STT",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestTotalStt.toLocaleString(
            "id"
          )}</div>`;
        },
      },
      {
        name: "Total Koli",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestTotalPiece.toLocaleString(
            "id"
          )}</div>`;
        },
      },
      {
        name: "Tanggal Manifest",
        styleHead: "w-40 text-left",
        render: (item: StiDestData) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.stiDestCreatedAt
          )}</div>
            <div class="capitalize text-gray-lp-500 break-all">${
              item.stiDestCreatedBy
            }</div>`;
        },
      },
      {
        name: "Transit",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestTransit?.toLocaleString(
            "id"
          )}</div>`;
        },
      },
      {
        name: "Misroute",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestMisroute?.toLocaleString(
            "id"
          )}</div>`;
        },
      },
      {
        name: "Segera POD",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left font-semibold">${item.stiDestStatusReturnPod?.toLocaleString(
            "id"
          )}</div>`;
        },
      },
      {
        name: "Segera RTS",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left font-semibold">${item.stiDestStatusReturnRts?.toLocaleString(
            "id"
          )}</div>`;
        },
      },
      {
        name: "Segera HAL",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left font-semibold">${item.stiDestStatusReturnHal?.toLocaleString(
            "id"
          )}</div>`;
        },
      },
      {
        name: "Atur",
        key: "button_column",
        styleHead: "w-24 text-left",
        styleCustom: "padding-action-sti",
        styleButton: (item: any) => {
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            customClass: "button-option",
            clickFunction: () => null,
            groupOptions: [
              {
                icon: "print-summary",
                label: "Print Ringkasan",
                clickFunction: () => this.printSummary(item.stiDestId),
              },
              {
                icon: "printer",
                label: "Print PDF",
                clickFunction: () => this.printManifest(item.stiDestId),
              },
              {
                icon: "download-gray",
                label: "Download Excel",
                clickFunction: () =>
                  StiDestController.downloadExcelStiDestV2({id: item.stiDestId, version: "v2"}),
              },
            ],
          };
        },
      },
    ];
  }

  // custom button
  customButton = {
    outline: true,
    title: "Print",
    textColor: "black-lp-300",
    color: "gray-lp-400",
    small: false,
    clickFunction: (item: any) => this.refs.print.printManifest(item.stiDestId),
  };

  get pagination() {
    return StiDestController.stiDestList.pagination;
  }

  get stiDestList() {
    return StiDestController.stiDestList.data;
  }

  async printSummary(id: number) {
    const printManifest = await StiDestController.getDetailStiDest({
        id: Number(id),
        version: "v2",
      });
  
      if (printManifest) {
        const detail: StiDestDetail = StiDestController.stiDestDetail;
        const data: Array<any> = [
          {
            name: "Kota Kedatangan",
            value: `${detail.originCityCode} - ${detail.originConsolidator}`
          },
          {
            name: "Nama Consolidator",
            value: detail.stiDestPartnerName
          },
          {
            name: "Tanggal Manifest",
            value: formatDateNormal(detail.stiDestDate, "DD MMMM YYYY HH:mm:ss")
          },
          {
            name: "Total STT/Koli",
            value: `${String(detail.totalStt)}/${String(detail.totalPiece)}`
          },
          {
            name: "Transit",
            value:detail.stiDestTotalTransit === 0 ? "Tidak" :detail.stiDestTotalTransit
          },
          {
            name: "Misroute",
            value:detail.stiDestTotalMisroute === 0 ? `Tidak` :detail.stiDestTotalMisroute
          },
          {
            name: "Segera POD",
            value:detail.stiDestTotalStatusReturnPOD === 0 ? `Tidak` :detail.stiDestTotalStatusReturnPOD
          },
          {
            name: "Segera RTS",
            value:detail.stiDestTotalStatusReturnRTS === 0 ? `Tidak` :detail.stiDestTotalStatusReturnRTS
          },
          {
            name: "Segera HAL",
            value:detail.stiDestTotalStatusReturnHal === 0 ? `Tidak` :detail.stiDestTotalStatusReturnHal
          }
        ];
  
        const print: any = await import(
          "@/app/ui/views/incoming-process/sti-dest/modules/print-reverse-scan.vue"
        );
        const logo = await import(
          `@/app/ui/assets/images/logo-${this.roleAccount}.png`
        );
        print.default.methods.printSummary(data, id, logo.default);
      }
    }

  async printManifest(id: number) {
      const printManifest = await StiDestController.getDetailStiDest({
        id: Number(id),
        version: "v2",
      });
  
      if (printManifest) {
        const detailManifest = StiDestController.stiDestDetail;
        const print: any = await import(
          "@/app/ui/views/incoming-process/sti-dest/modules/print-reverse-scan.vue"
        );
        const logo = await import(
          `@/app/ui/assets/images/logo-${this.roleAccount}.png`
        );
        print.default.methods.printManifest(detailManifest, logo.default);
      }
    }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get dataProfile() {
    return AccountController.accountData;
  }
}
